import React, { useState } from "react";
import "./styles.css";
import { Button, Modal } from "antd";
import { t } from "../tools";
import packageJson from "../../package.json";

function MainMenu({ lang, navigate }) {
  const [modalVisible, setShowModal] = useState(false);
  const cat_list = [
    "sweet",
    "romantic",
    "dailywords",
    "horror",
    "question",
    "fantasy",
  ];
  const onCatClick = (cat) => {
    navigate("/el", { state: { lang, cat } });
  };

  return (
    <div id="root-container">
      <div id="container">
        <div id="long-credit">{t("long_credit", "app_words", lang)}</div>
        <div className="text-shadow" id="tap-here">
          {t("tap_here", "app_words", lang)}
        </div>

        <Button
          id="new-random"
          onClick={() =>
            navigate("/el", {
              state: {
                lang,
                cat: cat_list[Math.floor(Math.random() * cat_list.length)],
              },
            })
          }
        >
          {t("new_random", "app_words", lang)}
        </Button>

        <div className="text-shadow" id="tap-here">
          {t("can_also_choose_topic", "app_words", lang)}
        </div>

        <div id="cat-container">
          <div>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(
                        50% 960.25% at 50% 50%,
                        #fa00ff 0%,
                        rgba(157, 190, 255, 0.708333) 85.42%,
                        rgba(189, 211, 255, 0) 100%
                      )`,
              }}
              onClick={() => onCatClick("sweet")}
            >
              {t("sweet", "app_words", lang)}
            </Button>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(50% 960.25% at 50% 50%, #EA3C31 0%, rgba(157, 190, 255, 0.708333) 85.42%, rgba(189, 211, 255, 0) 100%)`,
              }}
              onClick={() => onCatClick("romantic")}
            >
              {t("romantic", "app_words", lang)}
            </Button>
          </div>
          <div>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(50% 960.25% at 50% 50%, #6C6F75 38.02%, rgba(45, 95, 191, 0) 100%)`,
              }}
              onClick={() => onCatClick("horror")}
            >
              {t("horror", "app_words", lang)}
            </Button>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(50% 960.25% at 50% 50%, #C3D3F0 0%, rgba(100, 152, 251, 0) 100%)`,
              }}
              onClick={() => onCatClick("dailywords")}
            >
              {t("dailywords", "app_words", lang)}
            </Button>{" "}
          </div>
          <div>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(50% 960.25% at 50% 50%, #FDDDD3 47.92%, rgba(237, 240, 246, 0) 100%)`,
              }}
              onClick={() => onCatClick("question")}
            >
              {t("question", "app_words", lang)}
            </Button>
            <Button
              className="badge"
              style={{
                background: `radial-gradient(50% 960.25% at 50% 50%, #BCEEAA 47.92%, rgba(237, 240, 246, 0) 100%) `,
              }}
              onClick={() => onCatClick("fantasy")}
            >
              {t("fantasy", "app_words", lang)}
            </Button>{" "}
          </div>
        </div>

        <div className="text-shadow" id="tap-here">
          {t("recommended_for", "app_words", lang)}
        </div>

        <Button id="about" onClick={() => setShowModal(true)}>
          {t("about", "app_words", lang)}
        </Button>
      </div>
      <Modal
        open={modalVisible}
        width="70%"
        title={
          <p style={{ textAlign: "center" }}>
            {t("thanks", "app_words", lang)}
          </p>
        }
        onCancel={() => setShowModal(false)}
        footer={[]}
        closable={false}
      >
        <div id="modal-content">
          <p>EL-Web {packageJson.version}</p>
          <p>{t("send_mail_instructions", "app_words", lang)}</p>
          <a href="mailto:sorapenstudio@gmail.com">sorapenstudio@gmail.com</a>
          <div id="modal-buttons-container">
            <Button key="back" onClick={() => setShowModal(false)}>
              {t("back", "app_words", lang)}
            </Button>

            <img
              onClick={() => navigate("/")}
              style={{ width: 25, height: 25 }}
              src={"/jp.png"}
            />
            <img
              onClick={() => navigate("/en")}
              style={{ width: 25, height: 25 }}
              src={"/uk.png"}
            />

            <a href="https://discord.gg/a3AvBqzhq7" target="_blank">
              <img style={{ width: 30, height: 30 }} src={"/discord.png"} />
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MainMenu;
