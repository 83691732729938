import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import MM from "./MM";
import EL from "./el";

const autoReload = () => {
  // Set the desired reload time (in 24-hour format)
  const reloadTime = "05:30:00";
  const domainName = window.location.hostname;
  // Get the current time
  const currentTime = new Date();

  // Calculate the time until the next reload
  let timeUntilReload =
    new Date(currentTime.toDateString() + " " + reloadTime) - currentTime;
  if (timeUntilReload < 0) {
    // If the reload time has already passed today, schedule the reload for tomorrow
    timeUntilReload += 24 * 60 * 60 * 1000;
  }
  // Schedule the reload using setInterval()
  setInterval(() => {
    window.location.href = "https://" + domainName;
  }, timeUntilReload);
};

function Routing() {
  const navigate = useNavigate();

  if (window.location.href.includes("1480"))
    window.gtag("event", "login", {
      method: "user_id",
      user_id: 1480,
    });

  autoReload();
  return (
    <Routes>
      <Route path="/" element={<MM lang="jp" navigate={navigate} />}></Route>
      <Route path="/en" element={<MM lang="en" navigate={navigate} />}></Route>
      <Route path="/el" element={<EL navigate={navigate} />}></Route>
    </Routes>
  );
}

export default Routing;
