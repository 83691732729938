import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Button } from "antd";
import "./styles.css";

function ButtonWithHeart({ handleClick, buttonText, catButton }) {
  const [showHearts, setShowHearts] = useState(false);

  const handleButtonClick = () => {
    handleClick(catButton);
    if (!showHearts) {
      setShowHearts(true);
      setTimeout(() => {
        setShowHearts(false);
      }, 1000);
    }
  };

  return (
    <div id="all-random-container">
      <div className="heart-container">
        {showHearts && (
          <div>
            {[...Array(4)].map((_, index) => (
              <FavoriteIcon
                key={index}
                className="heart"
                sx={{
                  zIndex: 999,
                  position: "absolute",
                  left: 40 + index * 5 + "vw",
                  bottom: 80,
                  width: 20,
                  height: 20,
                  animation: `fly 1s ease-out`,
                  color: "#ff008a",
                  animationDelay: `${index * 0.1}s`,
                }}
              />
            ))}
          </div>
        )}
      </div>
      <Button
        style={catButton ? { background: "#757575", color: "#fff" } : {}}
        id="random-button"
        onClick={handleButtonClick}
      >
        {buttonText}
      </Button>
    </div>
  );
}

export default ButtonWithHeart;
