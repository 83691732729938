import english from "./multilang/en";
import japanese from "./multilang/jp";

export const t = (key, type, lang) => {
  const jp = { ...japanese };
  const en = { ...english };
  const language = eval(lang);

  if (key == "ask_bf" || key == "ask_gf") {
    const app_words = language.app_words;
    const aite_key = key.split("_")[1];
    const aite = `{${key.split("_")[1]}}`;
    const parts = app_words[key].split(aite);
    return eval(
      <div>
        <>
          {parts[0]}
          <span
            style={
              lang == "jp"
                ? { color: "#FF0000" }
                : {
                    color: "#FF0000",
                    marginLeft: ".5rem",
                    marginRight: ".5rem",
                  }
            }
          >
            {app_words[aite_key]}
          </span>
          {parts[1]}
        </>
      </div>
    );
  }
  if (type == "el") {
    const el_list = eval(`${lang}.${key}_el`);
    const keys = Object.keys(el_list);
    const property_name = keys[[Math.floor(Math.random() * keys.length)]];
    let el = el_list[property_name];
    if (lang == "jp") el = `「${el}」`;
    if (property_name == "sweet_el_6")
      return el.replaceAll(`{random}`, Math.floor(Math.random() * 6) + 1);
    else return el;
  } else return eval(`${lang}.${type}.${key}`);
};
